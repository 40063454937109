import { createApp } from "vue";
import BootstrapVue from "bootstrap-vue-next";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "crypto-browserify";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.css";
// Создаем приложение Vue
const app = createApp(App);

// Устанавливаем переменные среды в глобальные свойства Vue
app.config.globalProperties.$api = process.env.VUE_APP_API_URL;
app.config.globalProperties.$crm = process.env.VUE_APP_CRM_URL;
app.config.globalProperties.$minio = process.env.VUE_APP_MINIO_URL;

// Загрузите JSON-файл с ключами и значениями для локализации
const locales = require("./locales.json");

// Глобальный метод для локализации
app.config.globalProperties.$localize = function (key, lang = "eng") {
  if (locales[lang] && locales[lang][key]) {
    return locales[lang][key];
  } else {
    return key;
  }
};

// Используем плагины и монтируем приложение
app.use(BootstrapVue);
app.use(router);
app.use(store);

// Получаем язык из localStorage при загрузке приложения
const savedLang = localStorage.getItem("lang");
store.commit("setLang", savedLang || "eng");

// Слушаем изменения языка и сохраняем их в localStorage
store.watch(
  (state) => state.lang,
  (newLang) => {
    localStorage.setItem("lang", newLang);
  }
);

app.mount("#app");
