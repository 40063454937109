import { createStore } from "vuex";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("storeState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("storeState", serializedState);
  } catch (err) {
    // Обработка ошибок сохранения состояния
  }
};

export default createStore({
  state: {
    isAuthenticated: loadState()?.isAuthenticated || false,
  },
  mutations: {
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
      saveState(state);
    },
    setLang(state, lang) {
      state.lang = lang;
      saveState(state); // Сохранение нового состояния при изменении языка
    },
  },
  getters: {
    getLang: (state) => state.lang,
    isAuthenticated: (state) => state.isAuthenticated,
  },
});
