<template>
  <div class="container-xxl mt-5 mb-5">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "emty-layout",
  mounted() {
    document.body.classList.toggle("dark-theme");
  },
};
</script>
