<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import "bootstrap/dist/css/bootstrap.min.css";
export default {
  computed: {
    layout() {
      const meta = this.$route.meta.layout;
      const layoutType = typeof meta === "string" ? meta : "empty";
      return layoutType + "-layout";
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "assets/main.css";
@import "assets/dark-mode.css";
</style>
