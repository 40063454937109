<template>
  <div>
    <li v-if="lg !== 'eng'">
      <button @click="setLang('eng')" class="dropdown-item">
        <img src="@/assets/img/eng.webp" alt="mdo" width="32" height="30" />
        <span class="user-top">&nbsp;ENG</span>
      </button>
    </li>
    <li v-if="lg !== 'ua'">
      <button @click="setLang('ua')" class="dropdown-item">
        <img src="@/assets/img/ua.webp" alt="mdo" width="32" height="30" />
        <span class="user-top">&nbsp;UA</span>
      </button>
    </li>
    <li v-if="lg !== 'pl'">
      <button @click="setLang('pl')" class="dropdown-item">
        <img src="@/assets/img/pl.webp" alt="mdo" width="32" height="30" />
        <span class="user-top">&nbsp;PL</span>
      </button>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lg: "en",
    };
  },
  mounted() {
    try {
      this.lg = this.$store.getters.getLang; // Используйте this.$store для доступа к хранилищу
    } catch (error) {
      console.error("Error in mounted:", error);
    }
  },
  methods: {
    setLang(language) {
      this.lg = language;
      this.$store.commit("setLang", language);
    },
  },
};
</script>
